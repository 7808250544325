<template>
  <div class="reset-password">
    <div class="d-flex justify-center">
      <v-card outlined tile>
        <v-card-title> Palauta salasana </v-card-title>
        <v-card-text>
          Syötä kenttään sähköpostiosoite, jota olet käyttänyt kirjautuessasi Loviisan Aitan verkkokauppaan.<br/>
          Lähetämme syötettyyn sähköpostiin linkin, jonka avulla voit vaihtaa salasanasi.
        </v-card-text>
        <v-card-text>
          <v-form ref="emailForm">
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Sähköpostiosoite"
                :rules="[rules.required, rules.email]"
                v-model="email"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn
                block
                primary
                depressed
                x-large
                rounded
                color="primary"
                @click="send"
              >
                Lähetä
              </v-btn>
            </v-col>
          </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<style scoped>
.reset-password {
  width: 100%;
}
</style>

<script>
export default {
  data () {
    return {
      email: "",
      url: process.env.VUE_APP_BBSHOP_ENDPOINT + "/authentication/forgot",
      response: {},
      rules: {
        required: (value) => !!value || "Kenttä ei voi olla tyhjä.",
        email: (value) => 
        /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) 
        || "Sähköpostin tulee olla oikeassa muodossa"
      }
    }
  },
  methods: {
    send: function() {
      if(this.$refs.emailForm.validate()){
        // console.log("Lähetetään!!")
        let data = {email: this.email}
        this.axios.post(this.url, data).then((response) => console.log(response))
      }
    }
  }
}
</script>